<template>
  <div class="ng-list">
    <Navigation/>
    <Breadcrumb :breadcrumb="state.listBreadcrumb"/>
    <div class="ng-list__container container">
      <div class="ng-list__container__main">
        <Preloaders v-if="state.preload"/>
        <div v-else>
          <div class="title">
            <span class="name">受講生の勤務先企業</span>
            <button
                type="button"
                class="btn-blue btn-sm shadow"
                :disabled="state.isSubmitting"
                @click="state.showConfirmModal=true"
            >
              マッチングNGリストを更新
            </button>
          </div>

          <div class="messages">
            NGリストに追加したい企業にチェックを入れて「マッチングNGリストを更新」ボタンをクリックしてください。
            <br/>
            ダイアログが表示されますので、「更新する」をクリックすると更新されます。
            <br/>
            NGリストから除外する場合は、除外したい企業のチェックを外し「マッチングNGリストを更新」ボタンをクリックし更新すると除外されます。
            <br/>
            <p class="alert">
              ※ブラックリスト登録をいただいた場合でも、受講生が所属企業を変更した場合はマッチングする場合があります。
            </p>
          </div>

          <div class="form-ctrl">
            <SelectOrganization
                id="organization_name"
                name="organization_name"
                :isSearch="true"
                @selected="onSelectOrganization"
                @unselected="onUnselectedOrganization"
            />
          </div>

          <div class="organization-length">
            {{ state.displayOrganizationList.length }} 件
          </div>

          <!-- 企業一覧 -->
          <table class="organization-table">
            <thead>
            <tr class="header-record">
              <th></th>
              <th>企業名</th>
              <th>住所</th>
              <th>ステータス</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-if="state.displayOrganizationList.length > 0"
                v-for="(organization, organizationIndex) in state.displayOrganizationList"
                :key="organizationIndex"
            >
              <td>
                <input
                    type="checkbox"
                    :id="organization.organization_name"
                    :value="organization.corporate_number"
                    v-model="state.checkedCorporateNumbers"
                />
                <label
                    class="checkbox"
                    :for="organization.organization_name"
                />
              </td>
              <td>{{ organization.organization_name }}</td>
              <td>{{ organization.address }}</td>
              <td>{{ organization.ng ? "追加済み" : "-" }}</td>
            </tr>
            <tr v-else>
              <td colspan="4" class="no-list">企業が見つかりませんでした</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>

  <ConfirmModal
      v-if="state.showConfirmModal"
      title="NGリスト更新"
      okText="更新"
      :description="`NGリストを更新します。\nよろしいですか`"
      :isSubmitting="state.isSubmitting"
      @confirmed="onSubmit"
      @canceled="state.showConfirmModal=false"
  />
</template>

<script lang="ts" src="./NgList.ts"/>
<style lang="scss" src="./NgList.scss" scoped></style>
