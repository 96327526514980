import { __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted } from "vue";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import Preloaders from "@/presentation/components/preloader/Preloaders.vue";
import ConfirmModal from "@/presentation/components/modals/confirm/Confirm.vue";
import SelectOrganization from "@/presentation/components/select-organization/SelectOrganization.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
export default defineComponent({
    name: "Engineer",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        Preloaders: Preloaders,
        ConfirmModal: ConfirmModal,
        SelectOrganization: SelectOrganization,
    },
    setup: function () {
        var state = reactive({
            preload: true,
            listBreadcrumb: [
                { name: "ダッシュボード", route: "/organization/dashboard/projects" },
                { name: "NGリスト", route: "" }
            ],
            isSubmitting: false,
            displayOrganizationList: [],
            sourceOrganizationList: [],
            checkedCorporateNumbers: [],
            showConfirmModal: false,
        });
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        function onUnselectedOrganization() {
            state.displayOrganizationList = state.sourceOrganizationList;
        }
        function onSelectOrganization(organization) {
            state.displayOrganizationList = state
                .sourceOrganizationList
                .filter(function (srcOrg) { return srcOrg.corporate_number === organization.corporateNumber; });
        }
        function onSubmit() {
            state.isSubmitting = true;
            var params = {
                corporate_numbers: state.checkedCorporateNumbers,
            };
            organizationInteractor.updateOrganNgCorporates(params).then(function () {
                return getNgList();
            }).then(function () {
                state.isSubmitting = false;
                state.showConfirmModal = false;
            }).catch(function () {
                alert("NGリストの更新に失敗しました。");
                state.isSubmitting = false;
                state.showConfirmModal = false;
            });
        }
        function getNgList() {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, organizationInteractor.getOrganNgCorporates()];
                        case 1:
                            res = _a.sent();
                            if (res && res.data) {
                                state.displayOrganizationList = state.sourceOrganizationList = res.data;
                                state.checkedCorporateNumbers = res.data
                                    .filter(function (corporate) { return corporate.ng && corporate.corporate_number; })
                                    .map(function (corporate) { return corporate.corporate_number; });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        onMounted(function () {
            getNgList().then(function () {
                state.preload = false;
            });
        });
        return {
            state: state,
            onUnselectedOrganization: onUnselectedOrganization,
            onSelectOrganization: onSelectOrganization,
            onSubmit: onSubmit,
        };
    }
});
